body {
  background-color: #000;
}

#root {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-v {
  align-self: center;
  text-align: center;
}

.link-icons img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
  transition: all .5s ease-in-out;
}

.link-icons img:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

.coming-soon {
  color: #F00;
  font-family: 'Courier New', Courier, monospace;
  transform: rotate(-30deg);
  position: absolute;
  z-index: 1;
  font-size: 10px;
  margin-top: 15px;
  margin-left: -7px;
  text-decoration: none;
}

.footer {
  margin-top: 30px;
  font-size: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}
